import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ApiService } from "./api/api.service";
import { Claim } from "../models/claims.model";
import { delay, filter, map, take } from "rxjs/operators";
import { STSActions } from "../@store/actions/sts.actions";
import { Store } from "@ngrx/store";
import { AppState } from "../@store/reducers";
import { claimTypes } from "../appsettings";
import { LoggerService } from "./logger.service";
import { ConfigService } from "./config/config.service";
import {
  getLangCodeFromLocalStorage,
  getLangCodeFromQueryString,
} from "../shared/component-modules/core/util/common.utils";
import { isInAvailableLocale } from "../transloco-root.module";
import { TranslocoService } from "@ngneat/transloco";
import { localStorageKeys } from "../shared/model/storage.model";
import { formatLocale } from "../plasma-ui-common/utils/format-locale";
import { intersection } from "lodash";

@Injectable({
  providedIn: "root",
})
export class UserClaimsService {
  private userClaimsIsLoaded: boolean = false;
  public claims$ = new BehaviorSubject(null);

  public country$ = this.claims$.pipe(filter(i=>!!i), map((u) => u?.country));
  public isPatient$ = of(true); /** all users can access the site */
  public sub$ = this.claims$.pipe(filter(i=>!!i), map((claims) => claims.sub));
  public patientSsn$ = this.claims$.pipe(filter(i=>!!i), map((claims) => claims?.ssn));

  constructor(
    private readonly apiService: ApiService,
    private store: Store<AppState>,
    private logger: LoggerService,
    private config: ConfigService,
    private transloco: TranslocoService
  ) {}

  private fetchClaims(sub: string): Observable<Claim[]> {
    return this.apiService.get<Claim[]>(`/manage/accounts/${sub}`);
  }

  public async getUserClaims(userId: string) {
    try {
      if (!this.userClaimsIsLoaded) {
        await of(true).pipe(delay(1500)).toPromise();
        const rawClaims = await this.fetchClaims(userId).toPromise();

        const claims = this.getUserClaimsAsObject(rawClaims);
        this.userClaimsIsLoaded = true;
        this.claims$.next(claims);
      }
    } catch (error) {
      this.logger.log("getUserClaims error", error);
    }
  }

  private getUserClaimsAsObject(claims: Claim[]): Record<string, any> {
    this.store.dispatch(STSActions.loadProfileSuccess({ claims }));

    const userId: string = claims.find(
      (z) => z.claimType == claimTypes.USERID
    ).claimValue;
    const usertypes = claims
      .filter((claim) => claim.claimType == claimTypes.USER_TYPE)
      .map((usertype) => usertype.claimValue);

    return {
      ...claims.reduce((a, v) => ({ ...a, [v.claimType]: v.claimValue }), {}),
      user_type: usertypes,
      sub: userId,
    };
  }

  public async setAppLanguageFromJwtToken(lang: string) {
    await this.config.envConfig$
      .pipe(
        filter((config) => Boolean(config)),
        take(1)
      )
      .toPromise();

    const getLang = isInAvailableLocale;
    let availableLanguages = [
      this.transloco.getDefaultLang(),
      ...this.getAvailableLanguages(),
    ];

    const fromStorage = getLangCodeFromLocalStorage();
    const fromQString: string = getLangCodeFromQueryString();
    const fromJwtLangCode: boolean =
      lang && getLang(lang, availableLanguages) != "";

    if (!fromQString && !fromStorage && fromJwtLangCode) {
      const langToSet = formatLocale(getLang(lang, availableLanguages), this.config._envConfig as any);
      sessionStorage.setItem(localStorageKeys.langCode, langToSet);
      this.transloco.setActiveLang(langToSet);

      this.logger.log("LOCALE has been set from JWT", langToSet);
    }
  }

  private getAvailableLanguages(): string[] {
    return this.config._envConfig?.AvailableLanguage?.split(",") || [];
  }

  checkUserAccess(types = []): Observable<boolean> {
    return this.claims$.pipe(
      filter((claims) => Boolean(claims)),
      map((claims) => {
        const userTypes = (claims.user_type as string[]) || [];
        const allowedUsers = types.map((c) => c.toLowerCase());

        if (types.some((n) => n == "all")) return true;
        return intersection(
          userTypes.map((c) => c.toLowerCase()),
          allowedUsers
        ).length
          ? true
          : false;
      })
    );
  }
}
