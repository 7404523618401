<div data-test-id="suomi-ward-container" class="suomi-ward-container">
  <div data-test-id="suomi-ward-current-user" class="current-user">
    @if (suomiWard.showResultsForSuomiWard$|async) {
    <span data-test-id="suomi-ward-selected-name" class="name">{{suomiWard.fullName$ | async}}</span>
    <div data-test-id="suomi-ward-badge" class="ward-badge">
      <span data-test-id="suomi-ward-badge-text">{{'wardSelector.wardAccount' | transloco}}</span>
    </div>
    }
    @else {
    <span data-test-id="suomi-ward-logged-user-name" class="name">{{loggedUser}}</span>
    <span data-test-id="suomi-ward-logged-user-email" class="email-address">{{emailAddress}}</span>
    }
  </div>

  @if (suomiWard.wardInfo$|async) {
  <div data-test-id="suomi-ward-switch-to-parent" class="switch-to-parent">
    <div data-test-id="suomi-ward-parent" class="parent">
      <div data-test-id="suomi-ward-parent-label" class="ward label">{{'wardSelector.parent' | transloco}}</div>
      <div data-test-id="suomi-ward-parent-selector" class="selector">
        <span data-test-id="suomi-ward-parent-name" class="name">
          <span data-test-id="suomi-ward-parent-icon">
            <svg data-test-id="suomi-ward-parent-svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M11.6663 3C9.27311 3 7.33301 4.9401 7.33301 7.33333C7.33301 9.72657 9.27311 11.6667 11.6663 11.6667C14.0596 11.6667 15.9997 9.72657 15.9997 7.33333C15.9997 4.9401 14.0596 3 11.6663 3ZM9.33301 7.33333C9.33301 6.04467 10.3777 5 11.6663 5C12.955 5 13.9997 6.04467 13.9997 7.33333C13.9997 8.622 12.955 9.66667 11.6663 9.66667C10.3777 9.66667 9.33301 8.622 9.33301 7.33333Z"
                fill="#0077AD" />
              <path
                d="M8.33333 13C7.18406 13 6.08186 13.4565 5.2692 14.2692C4.45655 15.0819 4 16.1841 4 17.3333V19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19V17.3333C6 16.7145 6.24583 16.121 6.68342 15.6834C7.121 15.2458 7.71449 15 8.33333 15H15C15.6188 15 16.2123 15.2458 16.6499 15.6834C17.0875 16.121 17.3333 16.7145 17.3333 17.3333V19C17.3333 19.5523 17.781 20 18.3333 20C18.8856 20 19.3333 19.5523 19.3333 19V17.3333C19.3333 16.1841 18.8768 15.0819 18.0641 14.2692C17.2515 13.4565 16.1493 13 15 13H8.33333Z"
                fill="#0077AD" />
            </svg>
          </span>
          <span data-test-id="suomi-ward-parent-value" class="value">{{loggedUser}}</span>
        </span>

        @if (!(suomiWard.showResultsForSuomiWard$|async)) {
          <mat-radio-button data-test-id="suomi-ward-parent-radio" class="example-radio-button"
          checked></mat-radio-button>
        } @else {
          <mat-radio-button data-test-id="suomi-ward-parent-radio" class="example-radio-button"
          (change)="switchToParentAccount()"></mat-radio-button>
        }

      </div>
    </div>

    <div data-test-id="suomi-ward-selected-ward" class="selected-ward">
      <div data-test-id="suomi-ward-selected-ward-label" class="ward label">{{'wardSelector.ward' | transloco}}</div>
      <div data-test-id="suomi-ward-selected-ward-selector" class="selector">
        <span data-test-id="suomi-ward-selected-ward-name" class="name">
          <span data-test-id="suomi-ward-selected-ward-icon">
            <svg data-test-id="suomi-ward-selected-ward-svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_2083_23547)">
                <path
                  d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                  stroke="#0077AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M8.625 13.125C9.24632 13.125 9.75 12.6213 9.75 12C9.75 11.3787 9.24632 10.875 8.625 10.875C8.00368 10.875 7.5 11.3787 7.5 12C7.5 12.6213 8.00368 13.125 8.625 13.125Z"
                  fill="#0077AD" />
                <path
                  d="M15.375 13.125C15.9963 13.125 16.5 12.6213 16.5 12C16.5 11.3787 15.9963 10.875 15.375 10.875C14.7537 10.875 14.25 11.3787 14.25 12C14.25 12.6213 14.7537 13.125 15.375 13.125Z"
                  fill="#0077AD" />
                <path
                  d="M14.4844 15.75C13.7481 16.239 12.8839 16.4998 12 16.4998C11.1161 16.4998 10.2519 16.239 9.51562 15.75"
                  stroke="#0077AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M12 3C10.5 4.875 10.5 6.75 10.5 6.75C10.5 7.14782 10.658 7.52936 10.9393 7.81066C11.2206 8.09196 11.6022 8.25 12 8.25C12.3978 8.25 12.7794 8.09196 13.0607 7.81066C13.342 7.52936 13.5 7.14782 13.5 6.75"
                  stroke="#0077AD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_2083_23547">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span data-test-id="suomi-ward-selected-ward-value" class="value">{{suomiWard.fullName$ | async}}</span>
        </span>

        @if (suomiWard.showResultsForSuomiWard$|async) {
          <mat-radio-button data-test-id="suomi-ward-selected-ward-radio" class="example-radio-button" checked></mat-radio-button>
        } @else {
          <mat-radio-button (change)="switchToWardAccount()" data-test-id="suomi-ward-selected-ward-radio" class="example-radio-button"></mat-radio-button>
        }
      </div>
    </div>


    <div data-test-id="suomi-ward-select-other" class="select-other">
      <button data-test-id="suomi-ward-select-other-button" type="button" class="btn" (click)="selectAnotherWardAccount()">
        <svg data-test-id="suomi-ward-select-other-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
          viewBox="0 0 24 24" fill="none" stroke="#003765" stroke-width="2.5" stroke-linecap="round"
          stroke-linejoin="round">
          <path d="M5 12h13M12 5l7 7-7 7" />
        </svg>
        <span data-test-id="suomi-ward-select-other-text">{{'wardSelector.selectAnotherWard' | transloco}}</span>
      </button>
    </div>

  </div>
  }
  @else {
  <div data-test-id="suomi-ward-select-ward-btn" class="select-ward-btn">
    <div data-test-id="suomi-ward-select-ward-label" class="ward">{{'wardSelector.wards' | transloco}}</div>
    <button data-test-id="suomi-ward-select-ward-btn-button" type="button" class="btn" (click)="selectAnotherWardAccount()">
      <svg data-test-id="suomi-ward-select-ward-svg" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 24 24" fill="none" stroke="#003765" stroke-width="2.5" stroke-linecap="round"
        stroke-linejoin="round">
        <path d="M5 12h13M12 5l7 7-7 7" />
      </svg>
      <span data-test-id="suomi-ward-select-ward-text">{{'wardSelector.accessWardsOnSuomiFi' | transloco}}</span>
    </button>
  </div>
  }

</div>
