<!-- Button trigger modal -->
<button data-test-id="popup-unauthorized-notAuthModalbtn"
  type="button"
  class="btn btn-primary"
  data-toggle="modal"
  data-target="#notAuthModal"
  [hidden]="true"
  #clickBtn
></button>

<!-- Modal -->
<div data-test-id="popup-unauthorized-notAuthModalLabel"
  class="modal fade"
  id="notAuthModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="notAuthModalLabel"
  data-backdrop="static"
  data-keyboard="false"
>
  <div data-test-id="popup-unauthorized-modal-dialog"  class="modal-dialog" role="document">
    <div data-test-id="popup-unauthorized-modal-content"  class="modal-content">
      <div data-test-id="popup-unauthorized-modal-header"  class="modal-header">
        <h5 data-test-id="popup-unauthorized-modal-title"  class="modal-title" id="notAuthModalLabel">Unauthorized action</h5>
      </div>
      <div data-test-id="popup-unauthorized-modal-body"  class="modal-body">
        <div data-test-id="popup-unauthorized-flex"  class="d-flex">
          <span data-test-id="popup-unauthorized-icon"  class="icon"><em data-test-id="popup-unauthorized-info-circle"  class="fas fa-info-circle"></em></span>
          <span data-test-id="popup-unauthorized-span" >
            <p data-test-id="popup-unauthorized-p1" class="mb-0">You have been logged out from SYNLAB Access.</p>
            <p data-test-id="popup-unauthorized-p2" class="mb-0">Click <strong data-test-id="popup-unauthorized-continue" >continue</strong> to log in again.</p>
          </span>
        </div>
      </div>
      <div data-test-id="popup-unauthorized-modal-footer"  class="modal-footer">
        <button data-test-id="popup-unauthorized-logoutbtn"
          type="button"
          class="btn btn-primary"
          (click)="logout()"
          data-dismiss="modal"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>
