import { Component, OnInit, Renderer2, Inject } from "@angular/core";
import { AuthService } from "src/app/service/auth/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { localStorageKeys } from "src/app/shared/model/storage.model";

@Component({
  selector: "app-signin-callback",
  templateUrl: "./signin-callback.component.html",
})
export class SiginCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.clearSuomiData();
    this.renderer.addClass(
      this.document.body,
      "name" in this.route.snapshot.data
        ? this.route.snapshot.data.name
        : "nindex"
    );
  }

  private clearSuomiData() {
    localStorage.removeItem(localStorageKeys.SUOMI_SELECTED_WARD_SYNLAB_ID);
    localStorage.removeItem(localStorageKeys.HAS_SELECTED_SUOMI_WARD);
  }

  ngOnInit() {
    this.authService.signinRedirectCallbackTrigger$.next(Date.now());
  }
}
